import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import "./navbar.css"
import logo from "../../assets/logo.png"

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  const Menu = () => (
    <>
      <p><a href='#home'>Home</a></p> 
      <p><a href='#whatintrinsico'>What We Do</a></p>
      <p><a href='#CTA'>Schedule Consultation</a></p>
    </>
  )

  return (
    <div className='lp__navbar'>
      <div className='lp__navbar-links'>
        <div className='lp__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='lp__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className="lp__navbar-sign">
            </div>           
            <div className="lp__navbar-menu">
                {toggleMenu 
                    ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/> 
                    : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)}/>
                }
                {toggleMenu && (
                    <div className="lp__navbar-menu_container scale-up-center">
                        <div className="lp__navbar-menu_container-links">
                          <p><a href="#home">Home</a></p>
                          <p><a href="#whatintrinsico">What We Do</a></p>
                          <p><a href="#features">Features</a></p>
                          <p><a href="#cta">Schedule Consultation</a></p>
                        </div>
                    </div>
                )}
            </div>      
    </div>
  )
}

export default Navbar