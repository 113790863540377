import React from 'react'
import { Feature } from "../../components"
import "./whatIntrinsico.css"

const WhatIntrinsico = () => {
  return (
    <div className='lp__whatintrinsico section__margin' id='whatintrinsico'> 
      <div className='lp__whatintrinsico-container'>
        <Feature title='What We Do' text='We are here to help small business owners leverage the power of their data.'/> 
      </div>
      <div className="lp__whatintrinsico-heading">
        <h1 className='gradient__text'>The Possibilities Are Endless</h1>
        <p>Explore What&apos;s possible</p> 
      </div>
      <div className='lp__whatintrinsico-container'>
          <Feature title="Serverless Stack Integration" text="Leverage the power of serverless cloud software to manage your business. Together, we will see if serverless is right for you." />
          <Feature title="Frontend Dashboards" text="Need a UI to display data to your customers or employees? We build data-visualization dashboards to do just this!" />
          <Feature title="Custom Software" text="Managing your data can be tricky, especially if you don't know how software can help. We will listen to the problem and help you identify how software can be leveraged in your business." />
      </div>
    </div>
  )
}

export default WhatIntrinsico