import React from 'react' 
import logo from '../../assets/logo.png' 
import './footer.css' 

const Footer = () => (
  <div className="lp__footer section__padding">
    <div className="lp__footer-heading">
      <h1 className="gradient__text">Let&apos;s Step Into A Sustainable Future Together</h1>
    </div>

    <div className="lp__footer-links_logo">
      <img src={logo} alt="logo" />
    </div>

    <div className="lp__footer-copyright" id="privacy-policy">
      <p>@2022 Intrinsico, LLC. All rights reserved.</p>
      <br />
      <p> Privacy Policy </p>
      <p>Our Climate Hours application does not collect personal information.
        Terms of Service

        Introduction
        These terms apply to the use of the Climate Hours application. 

        Intellectual Property Rights
        Intellectual property rights including text, software, graphics are owned by us.

        The Climate Hours applications uses open source software and data covered under common variations of open source licensing. Only the open source data and software can be used unless expressly dictated otherwise.

        Content
        We may modify the graphics or features of the application without providing notice.</p>
    </div>
  </div>
) 

export default Footer 