import React from 'react'
import './cta.css'

const CTA = () => (
  <div className="lp__cta" id="CTA">
    <div className="lp__cta-content">
      <h3> Schedule A Free Consultation &amp; Start Exploring The Endless Possibilities.</h3>
    </div>
    <div className="lp__cta-btn">
      <button type="button"><a href='https://docs.google.com/forms/d/1sblTmn-viJ4eHXvNfGzpc8pWMfDiRSalsGPehX3e6fQ/edit'>Get Started</a></button>
    </div>
  </div>
);

export default CTA