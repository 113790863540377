import React from 'react'
import { Features, Footer, Header, WhatIntrinsico} from './containers'
import { CTA, Navbar} from './components'

import "./App.css"

const App = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <Navbar />
                <Header />
            
            <WhatIntrinsico />
            <CTA />
            <Footer />
            </div>
        </div>
    )
}

export default App