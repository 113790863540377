import React from 'react'
import "./header.css"
import home from "../../assets/homet.png"

const Header = () => {
 
  return (
      <div className="lp__header section__padding" id="home">
          <div className="lp__header-content">
              <h1 className="gradient__text">INTRINSICO</h1>
              <h2 className="gradient__text">Empowering You</h2> 
              <h2 className="gradient__text">To Sustain Our World</h2>
  
              <div className="lp__header-content__input">
                  <input type="text" placeholder="Schedule A Free Consultation With Us" disabled="true"/>
                  <button type="button"><a href='https://docs.google.com/forms/d/1sblTmn-viJ4eHXvNfGzpc8pWMfDiRSalsGPehX3e6fQ/edit'>Get Started</a></button>
              </div>
          </div>
  
      <div className="lp__header-image">
          <img src={home} alt={"home"}/>
      </div>
  </div>
  )
}

export default Header